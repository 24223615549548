@font-face {
  font-family: AbrahamRegular;
  src: url(./fonts/Abraham_OTF/Abraham-Regular.otf);
}
@font-face {
  font-family: AbrahamBold;
  src: url(./fonts/Abraham_OTF/Abraham-Bold.otf);
}
@font-face {
  font-family: AbrahamLight;
  src: url(./fonts/Abraham_OTF/Abraham-Light.otf);
}


body {

  margin: 0;
  font-family: AbrahamRegular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
