:root {
    --white: #fff;
  /*  --orange: #f36c00; */
  }
  
  * {
    box-sizing: border-box;
  }
    
  button {
    cursor: pointer;
  }
  
  img {
    max-width: 100%;
  }
  
  .outer-container {
    margin: auto;
    display: flex;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.3);
    overflow: hidden;
  }
  
  .outer-container > div {
    flex: 1;
  }
  
  .outer-container .carousel-wrapper {
    background: var(--orange);
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .outer-container .carousel-wrapper p,
  .carousel-item {
    font-weight: 700;
    font-size: 20px;
    color: var(--white);
    line-height: 1.3;
    height: 100%;

  }
  
  .outer-container .content {
    background: var(--white);
  }
  
  .leading-text {
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 6px;
  }
  
  .carousel {
    height: 100%;
    display: flex;
    flex: 5;
    align-items: center;
    color: var(--white);
  }
  
  .carousel-button {
    display: flex;
    align-self: center;
    padding: 10px;
    max-width: 50px;
    border-radius: 30px;
    background: var(--orange);
    border: 2px solid var(--white);
  }
  
  .carousel-button svg {
    height: 26px;
    width: 26px;
  }
  .carousel-button path {
    fill: var(--white);
  }
  
  .slides {
    align-self: flex-start;
    flex: 1;
    width: 100%;
    overflow: hidden;
  }
  
  .carousel-inner {
    position: relative;
  }
  
  .carousel-inner::before,
  .carousel-inner::after {
    content: "";
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 110px;
    pointer-events: none;
  }
    
  .bookShelfText {

    position: absolute;
    color: #808080;
    top: 12%;
    left: 15%;
    font-weight: 400;
    font-size: 22px;
    z-index: 9999;

  }
  .carousel-item {
    position: absolute;
    background: none;
   /* background: blue; */
    border: none;
    padding: 0;
    margin: 0;
    opacity: 0;
    top: 112px;
    transition: transform 0.4s ease, opacity 0.4s ease;
  }
  
  .carousel-item.visible {
    opacity: 1;
  }


  .FourItemsPageColumn {
    position: relative;
    float: left;
    width: 50%;
    height: 50%
  }

  .libNotableBotton {
    position: absolute;
    bottom: 50%;
    left: 50%;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 22px;
    z-index: 9999;
    border: 1px solid white;
    font-weight: 500;
    text-align: center;
  }

  .libNotableBotton:hover {
    background-color: white;
    color: black;
  }

  .userShelfContainer {
    margin-top: 5%;
    margin-left: 24%;
  }

  .userImageContainer {
    float: left;
    width: 8%;
    overflow:hidden;
  }

  .userInfoContainer {
    float: left;
    width: 50%;
    font-size: 16px;
    margin-left: 2%;
  }
  
  .userImageClass {
    border-radius:50%;
    width: 100%;
  }

  .communityImagesContainer {
    height: 100%;
    margin-left: 15%;
    margin-top: 10%;
  }   

  .communityImageContainer {
    height: 34%;
    float: left;
    margin-right: 2%;
    margin-top: 1%;
    padding-bottom: 1%;
    width: 20%;
  }

  .communityImage {
    background-color: #f0f0f0;
    padding-right: 26%;
    padding-left: 26%;
    padding-top: 6%;
    padding-bottom: 4%;
  }

  .communityUserName {
    font-weight: 400;
    font-size: 16px;
    color: black;
    text-align: center;  }

  .bookShelfImagesContainer {
    height: 100%;
    margin-left: 15%;
  }   

  .bookShelfImageContainer {
    height: 34%;
    float: left;
    margin-right: 2%;
    margin-top: 1%;
    padding-bottom: 1%;
    width: 20%;
  }


  .ThreeItemsPageColumn {
    height: 65%;
    float: left;
    padding-top: 2.5%;
    padding-bottom: 5%;
    width: 20%;
  }
  .ThreeItemsPageColumn1 {
    margin-left: 15%;
    margin-right: 5%;
  }


  .ThreeItemsPageColumn2 {
    margin-right: 5%;
  }

  .ThreeItemsPageColumn3 {
    margin-right: 15%;
  }

  .TowItemsPageColumn1 {
    float: left;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 50%;
    height: 100%
  }

  .TowItemsPageColumnContainer1{
    width: 100%;
    padding-left: 40%;
    padding-right: 10%;
    height: 100%
  }


  .TowItemsPageColumn2 {
    float: left;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 50%;
    height: 100%
  }

  .TowItemsPageColumnContainer2 {
    width: 100%;
    padding-right: 40%;
    padding-left: 10%;
    height: 100%;
  }

  .itemsDetailsContainer2 {
    padding-left: 2%;
    padding-right: 2%;
  }

  .itemsDetailsTitle {
    font-size: 20px;
    margin-bottom: 5%
  }

  .itemsDetailsText {
    margin-bottom: 5%
  }

  .follow-container {
    width: 20%;
    margin-top: 2%;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 22px;
    z-index: 9999;
    background-color: white;
    border: 1px solid black;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
  }

  .follow-container:hover {
    background-color:/* #808080; */ black;
    color: white;
  }

/*
  .follow-container {
    background-color: white;
    width: 20%;
    margin-top: 2%;
    z-index: 9999;
  }

  .follow {
    border: 1px solid #000;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    padding: 6px 6px 6px 10px;
    color:  #808080;
  } */


  .conversation-container {
    background-color: white;
    width: 100%;
    vertical-align: middle;
    z-index: 9999;
  }

  .conversation {
    border: 1px solid #000;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    padding: 6px 6px 6px 10px;
    color:  #808080;
  }

  .conversation-login {
    float: right;
    font-size: 12px;
    margin-bottom: 1%;
  }

  .itemPartPage {
    padding-top: 5%;
    padding-bottom: 5%;
    padding-right: 18%;
    padding-left: 18%;
    width: 100%;
    height: 100%

  }


  .TowItemsPageImage {
    cursor: pointer;
    object-fit: cover;
    width: 100%;
    height: 100%

  }

  .itemContainer {
    overflow-x:hidden;
    overflow-y:hidden;

  }
  